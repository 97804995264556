import { useState } from 'react'
import { useRouter } from 'next/router'
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useFormat } from 'helpers/hooks/useFormat'
import { utagLink } from 'helpers/tealiumHelper'
import useCartCT from 'frontastic/hooks/useCart'
import { useAccountCT } from 'frontastic/contexts/accountCTContext'
import { useAuthV2User } from 'frontastic/contexts/authV2UserContext'
import { useLocale } from 'frontastic/contexts/localeContext'
import { useActiveMenuItem } from './hooks/useActiveMenuItem'
import { LoginForm } from './login-form'
import OrderStatusModal from './pages/account-dashboard/components/order-status'
import { useComposable } from '../composable-provider'
import { SidebarNav } from '../sidebar/sidebar-nav'

export enum AccountPageType {
  PAGE = 'page',
  DRAWER = 'drawer',
}

export type AccountFormTypes = 'login' | 'forgot_password' | 'create_account'

const DrawerWrapper = ({ accountContent, signInContent, isOpen, onClose, isLogged }) => (
  <Drawer isOpen={isOpen} onClose={onClose} placement="right" size={'md'}>
    <DrawerOverlay />
    {isLogged ? accountContent : signInContent}
  </Drawer>
)

export const AccountDrawer = () => {
  const { accountDrawer } = useComposable()
  const { formatMessage } = useFormat({ name: 'common' })
  const { loggedIn } = useAccountCT()
  const { country, currency } = useLocale()
  const { handleLogoutUser } = useAuthV2User()
  const { recalculateCart } = useCartCT()
  const [isOrderStatusModalOpen, setIsOrderStatusModalOpen] = useState(false)

  const handleLogout = async () => {
    try {
      await handleLogoutUser()
      // recalculateCart()
      utagLink(
        [
          'link',
          {
            tealium_event: 'signout',
            crm_customer_number: null,
            customer_id: null,
            user_loginstatus: false,
          },
        ],
        country || 'US',
        currency || 'USD',
      )
    } catch (e) {
      console.error('Error logging out the user', e)
    }
    accountDrawer.onClose()
  }

  const closeOrderStatusModal = () => {
    setIsOrderStatusModalOpen(false)
  }

  const title = formatMessage({ id: 'account.dashboard.title' })

  const activeItem = useActiveMenuItem()

  if (isOrderStatusModalOpen) {
    return <OrderStatusModal isOpen={isOrderStatusModalOpen} onClose={closeOrderStatusModal} />
  }

  return (
    <DrawerWrapper
      isOpen={accountDrawer.isOpen}
      onClose={accountDrawer.onClose}
      isLogged={loggedIn}
      accountContent={
        <DrawerContent maxW={'550px'} padding={0}>
          <DrawerHeader
            minH={'48px'}
            p={'8px 12px'}
            display={'flex'}
            borderBottom={{ base: '1px solid #E2E2E2', md: 'none' }}
          >
            <DrawerCloseButton size={'md'} fontSize={'sm'} left={{ base: 'initial', md: '10px' }} />
            <Center width={'full'} display={'flex'}>
              <Text textStyle={'heading-desktop-100'}>{formatMessage({ id: 'account.dashboard.title' })}</Text>
            </Center>
          </DrawerHeader>
          <DrawerBody p={{ base: '24px 16px', md: '24px 64px 60px 64px' }}>
            {/* Desktop */}
            <VStack align="stretch" spacing={'0'} display={{ base: 'none', md: 'flex' }}>
              <SidebarNav activeItem={activeItem} size="Large" logout={handleLogout} />
            </VStack>
            {/* Mobile */}
            <VStack align="stretch" spacing={'0'} display={{ base: 'flex', md: 'none' }}>
              <SidebarNav activeItem={activeItem} size="Small" logout={handleLogout} />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      }
      signInContent={
        <DrawerContent maxW={'550px'} padding={0}>
          <DrawerHeader
            minH={'48px'}
            p={'8px 12px'}
            display={'flex'}
            borderBottom={{ base: '1px solid #E2E2E2', md: 'none' }}
          >
            <DrawerCloseButton size={'md'} fontSize={'sm'} left={{ base: 'initial', md: '10px' }} />
            <Center width={'full'} display={'flex'}>
              <Text textStyle={'heading-desktop-100'}>{title}</Text>
            </Center>
          </DrawerHeader>
          <DrawerBody p={{ base: '24px 16px', md: '24px 64px 60px 64px' }}>
            <LoginForm type={AccountPageType.DRAWER} />
          </DrawerBody>
        </DrawerContent>
      }
    />
  )
}
